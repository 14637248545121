import React, { Component } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import AlertMsn from './Common/AlertMsn';


export default class FrameAccess extends Component {
  constructor(props) {
    super(props);
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.state = {
      AccessMap: [
          { a: 'Menu de login', b: 'Habilitar control de usuarios', c: 'Planillas web', d: 'Fondos dinamicos', e: '', f: 'proximamente' },
        { a: 'Acceso a la web3', b: 'Escalamientos ', c: 'Verificacion instantanea', d: 'Decentralizacion', e: '', f: 'proximamente' },
        { a: 'Graficos pastel', b: 'Grafica de barras', c: 'BigData', d: 'Marco y sombra', e: '', f: 'proximamente' },
      //  { a: 'FX maker', b: 'Diseña tus propia publicidad', c: 'Has ese marketing influyente', d: 'Sencillo e intuitivo', e: '', f: 'proximamente' },
      ],
      starCount: 100,
      userCLABE: '',
      modalAlert: false,  
    }; 
  }

  componentDidMount(){
    this.setState({modalAlert:false})
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.modalAlert && !prevState.modalAlert) {
      this.autoCloseTimer = setTimeout(() => {
        this.handleCloseAlert();
      }, 2000);
    }

    if (!this.state.modalAlert && prevState.modalAlert) {
      clearTimeout(this.autoCloseTimer);
    }
  }

  handleCloseAlert() {
    this.setState({ modalAlert: false });  
  };

  handleBuyBtn(a) {
    this.setState({
      modalAlert: true,
      txtMainAlert: 'El Massin se encuentra en su maxima capacidad... en unos menses 22/11/24s.',
      txtTitleAlert: 'Tranki',
      alertState: 'warning',
    });
    return;
  }

  renderStars = () => {
    const { starCount } = this.state;
    const starsArray = [];
    for (let i = 0; i < starCount; i++) {
      const size = Math.random() * 3 + 1;
      const style = {
        left: `${Math.random() * 100}vw`,
        width: `${size}px`,
        height: `${size}px`,
        animationDuration: `${Math.random() * 5 + 5}s`,
        animationDelay: `${Math.random() * 5}s`,
      };
      starsArray.push(<div key={i} className="star" style={style}></div>);
    }
    return starsArray;
  };


  render() {
    return (
      <>
        <AlertMsn
          modalAlert={this.state.modalAlert}
          alertState={this.state.alertState}
          txtTitleAlert={this.state.txtTitleAlert}
          txtMainAlert={this.state.txtMainAlert}
          handleCloseAlert={this.handleCloseAlert}
        />
        <div className='container'>
          <div className="stars">
            {this.renderStars()}
          </div>
          <Row className='center-a'>

            {this.state.AccessMap.map((s, index) => (
              <Col key={index} sm={4} className='flex-inline justify-center'>
                <Card className="glass-card" onClick={() => { this.handleBuyBtn(s.f) }}>
                  <Card.Header><h5>{s.a}</h5></Card.Header>
                  <Card.Body>
                    <p>{s.b}</p>
                    <p>{s.c}</p>
                    <p>{s.d}</p>
                    <p>{s.e}</p>
                  </Card.Body>
                  <Card.Footer><strong>{s.f}</strong></Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>          
        </div>
      </>
    );
  }
}
