import React, { Component } from 'react'
import { Alert, Button, Card, CardBody, CardFooter, CardTitle, FormControl, InputGroup } from 'react-bootstrap'
import { FaCircleChevronLeft, FaRegCalendar, FaRegCalendarCheck } from 'react-icons/fa6'
import { LoadProduction } from '../store/MetaStore'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import db from '../firebase'
import AlertMsn from './Common/AlertMsn'

export default class FramePays extends Component {
    constructor(props) {
        super(props)
        this.state = LoadProduction.init
        this.handleCloseAlert = this.handleCloseAlert.bind(this)
    }

    cleanPathname() {
        const { pathname } = window.location;
        const decodedPathname = decodeURIComponent(pathname);
        return decodedPathname.startsWith('/admin/')
            ? decodedPathname.substring('/admin/'.length)
            : decodedPathname;
    }

    async componentDidMount() {
        const { pathname } = window.location;
        const cleanedPathname = this.cleanPathname()
        const license = await doc(db, '$:__sites-name', cleanedPathname)
        this.setState({ nameSite: cleanedPathname })
        if (cleanedPathname !== '') {
            const isLicenseAvalible = await getDoc(license)
            this.state = isLicenseAvalible.data()
            isLicenseAvalible.exists() ?
                this.setState({
                    ...isLicenseAvalible.data(),
                    pageExist: true
                })
                :
                setTimeout(() => {
                    alert('este sitio no existe'); window.location = '/'
                }, 1500);
        }
    }



    handleCloseAlert() {
        this.setState({ modalAlert: false });
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.modalAlert && !prevState.modalAlert) {
            this.autoCloseTimer = setTimeout(() => {
                this.handleCloseAlert();
            }, 1450);
        }

        if (!this.state.modalAlert && prevState.modalAlert) {
            clearTimeout(this.autoCloseTimer);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.autoCloseTimer);
    }


    handleToggleEnableCardPay() {
        this.state.enableCardPay === false ? this.setState({ enableCardPay: true }) : this.setState({ enableCardPay: false })
    }

    async handleSetState() {

        try {
            await updateDoc(doc(db, '$:__sites-name', this.state.siteName), this.state);

            this.setState({
                modalAlert: true,
                txtMainAlert: 'Configuración emparejada con el servidor',
                txtTitleAlert: 'Mi sistemita',
                alertState: 'success',
            });
        } catch (e) {
            this.setState({
                modalAlert: true,
                txtMainAlert: 'Error al emparejar con el servidor',
                txtTitleAlert: 'Mi sistemita',
                alertState: 'danger',
            });
        }

    }

    handleBackButtonClick = () => {
        if (this.props.onCrossBtn) {
            this.props.onCrossBtn();
        } else {

        }
    };

    render() {
        return (
            <div>
                <AlertMsn
                    modalAlert={this.state.modalAlert}
                    alertState={this.state.alertState}
                    txtTitleAlert={this.state.txtTitleAlert}
                    txtMainAlert={this.state.txtMainAlert}
                    handleCloseAlert={this.handleCloseAlert}
                />
                <Card>

                    <CardBody className='t-center'>
                        < FaCircleChevronLeft className=' z-10' onClick={() => { this.handleBackButtonClick() }} />
                    </CardBody>

                    <CardBody>
                        <Card>
                            <CardBody>
                                <CardTitle>Habilitar pagos por transferencia</CardTitle>
                                <InputGroup>
                                    <FormControl
                                        placeholder={this.state.txtCLABE !== '' ? this.state.txtCLABE : 'Ingresa tu cuenta CLABE'}
                                        maxLength={18}
                                        onChange={(e) => { this.setState({ txtCLABE: e.target.value }) }}
                                    />
                                </InputGroup>
                            </CardBody>
                            <CardBody>

                                <CardTitle>Habilitar pagos con tarjeta y mergadopago</CardTitle>
                                < div style={{ display: 'inline-flex', cursor: 'pointer' }}>

                                    {this.state.enableCardPay === true ? <FaRegCalendarCheck onClick={() => { this.handleToggleEnableCardPay() }} /> : <FaRegCalendar onClick={() => { this.handleToggleEnableCardPay() }} />}

                                </div>
                                <InputGroup>
                                    <FormControl
                                        placeholder={this.state.userPublicKey === '' ? 'Ingresa tu Public Key' : this.state.userPublicKey}                                        
                                        value={this.state.userPublicKey === '' ? 'Ingresa tu Public Key' : this.state.userPublicKey}
                                        onChange={(e) => {
                                            this.setState({ userPublicKey: e.target.value })
                                            if (e.target.value.length >= 43) {
                                                this.setState({ correctPublicKey: true });
                                            } else {
                                                this.setState({ correctPublicKey: false });
                                            }
                                        }}
                                    />
                                </InputGroup>
                                <br />
                                <InputGroup>
                                <span>Ingresa tu Access Token de mercadopago</span>
                                    <FormControl
                                        placeholder={this.state.userAccessToken === '' ? 'Ingresa tu Access Token' : this.state.userAccessToken}                                       
                                        type='password'
                                        value={this.state.userAccessToken === '' ? 'Ingresa tu Access Token' : this.state.userAccessToken}
                                        onChange={(e) => {
                                            this.setState({ userAccessToken: e.target.value })
                                        }}
                                    />
                                </InputGroup>
                            </CardBody>
                            <CardFooter>
                                <Button onClick={() => { this.handleSetState() }}>Aplicar Cambios </Button>
                            </CardFooter>
                        </Card>
                    </CardBody>
                </Card>

            </div>
        )
    }
}
