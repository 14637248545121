import React, { Component } from 'react';
import { Nav, Navbar, NavbarBrand, NavLink } from 'react-bootstrap';
import { LoadHomePage } from '../store/MetaStore';
import LoadHome from './LoadHome';
import LoadAccess from './LoadAccess';
import Tutoriales from './Tutoriales';
import FrameAccess from './FrameAccess';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...LoadHomePage.init,
      hrefOs: LoadHomePage.init.hrefOs || 'home',
    };
  }

  renderContent = () => {
    switch (this.state.hrefOs) {
      case 'home':
        return <LoadHome key="home" />;
      case 'tutoriales':
        return <Tutoriales key="tutoriales" />;
      case 'accesos':
        return <LoadAccess key="accesos" />;
      default:
        return null;
    }
  };

  render() {
    return (
      <div className="App">
        <Navbar expand="lg">
          <NavbarBrand
            className="rounded-font"
            onClick={() => this.setState({ hrefOs: 'home' })}
          >
            Mi sistemita
          </NavbarBrand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <NavLink onClick={() => this.setState({ hrefOs: 'tutoriales' })}>
                Tutoriales
              </NavLink>
              <NavLink onClick={() => this.setState({ hrefOs: 'accesos' })}>
                Actualizaciones
              </NavLink>
              <NavLink href="/Creatuapp">Crear App</NavLink>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="led-background nav" style={{ height: '2px' }}></div>
        <div key={this.state.hrefOs}>{this.renderContent()}</div>
        <div className="led-background nav" style={{ height: '1px' }}></div>
      </div>
    );
  }
}