import React, { Component } from 'react'
import './App.css';
import { LoadApp, LoadProduction } from './store/MetaStore';
import StartPage from './Component/StartPage';
import AdminStyleMain from './Component/AdminStyleMain';
export default class App extends Component {

  constructor(props) {
    super(props)
    this.state = LoadProduction.init
    this.handleChangeStep = this.handleChangeStep.bind(this);    
  }

  handleChangeStep(c) {
    this.setState({ pageSelectorMain: c })
  }

  render() {
    return (
      <div className='App'>
        <header className='App-header'>
          {this.state.pageSelectorMain ? <StartPage nextFrame={this.handleChangeStep} />:           
          <AdminStyleMain />}
        </header>
      </div>
    )
  }
}

