import React, { Component } from 'react';
import axios from 'axios';

class RemoveBgUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            processedImage: null,
            loading: false
        };
    }

    handleImageChange = (e) => {
        this.setState({ image: e.target.files[0] });
    };

    handleRemoveBackground = async () => {
        const { image } = this.state;
        if (!image) {
            alert('Por favor, sube una imagen primero.');
            return;
        }

        const formData = new FormData();
        formData.append('image_file', image);
        formData.append('size', 'auto');

        this.setState({ loading: true });

        try {
            const response = await axios.post(
                'https://api.remove.bg/v1.0/removebg',
                formData,
                {
                    headers: {
                        'X-Api-Key': 'r2EYGzBrco2coVfsj7RLXnxC', 
                        'Content-Type': 'multipart/form-data'
                    },
                    responseType: 'blob'
                }
            );

            const imageBlob = response.data;
            const imageUrl = URL.createObjectURL(imageBlob);
            this.setState({ processedImage: imageUrl });
        } catch (error) {
            console.error('Error al eliminar fondo:', error);
            alert('Error al eliminar el fondo. Revisa tu API Key o la imagen.');
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const { processedImage, loading } = this.state;
        return (
            <div style={{ textAlign: 'center' }}>
                <h1>Elimina el Fondo de una Imagen</h1>
                
                <input 
                    type="file" 
                    onChange={this.handleImageChange} 
                    accept="image/*" 
                />
                
                <button 
                    onClick={this.handleRemoveBackground} 
                    disabled={loading}
                >
                    {loading ? 'Procesando...' : 'Eliminar Fondo'}
                </button>

                {processedImage && (
                    <div>
                        <h3>Imagen Procesada:</h3>
                        <img 
                            src={processedImage} 
                            alt="Imagen Procesada" 
                            style={{ maxWidth: '300px' }} 
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default RemoveBgUploader;