import React, { Component } from 'react';
import { Card, CardBody, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import { FaChartSimple, FaDisplay, FaGear, FaListCheck, FaMoneyCheckDollar, FaPaintbrush, FaEarthAmericas } from 'react-icons/fa6';
import LoadAdmin from './LoadAdmin';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import db from '../firebase';
import { LoadProduction } from '../store/MetaStore';
import FrameAdmin from './FrameAdmin';
import FrameCharts from './Common/FrameCharts';
import FramePays from './FramePays';
import Cique from './Cique/Cique';
import NFXmaker from './Common/NFXmaker';
import GifGenerator from './Common/GifGenerator';
import FrameAdminTickets from './FrameAdminTickets';
import FrameLandinpage from './Common/FrameLandinpage';

export default class LoadMain extends Component {
    constructor(props) {
        super(props);
        this.state = LoadProduction.init
    }

    componentDidMount() {
        this.loadSiteData();
    }

    cleanPathname() {
        const { pathname } = window.location;
        const decodedPathname = decodeURIComponent(pathname);
        return decodedPathname.startsWith('/admin/')
            ? decodedPathname.substring('/admin/'.length)
            : decodedPathname;
    }

    async loadSiteData() {
        const cleanedPathname = this.cleanPathname();
        const license = doc(db, '$:__sites-name', cleanedPathname);
        this.setState({ nameSite: cleanedPathname });

        if (cleanedPathname) {
            const isLicenseAvailable = await getDoc(license);
            if (isLicenseAvailable.exists()) {
                this.setState({
                    ...isLicenseAvailable.data(),
                    pageExist: true,
                });
            }
        }
    }

    toggleDisplay = (type) => {
        if (type === 'admin') {
            this.setState({ displayCharts: 'admin' })
        }
        if (type === 'main') {
            this.setState({ displayCharts: 'main' })
        }
        if (type === 'chart') {
            this.setState({ displayCharts: 'chart' })
        }
        if (type === 'pays') {
            this.setState({ displayCharts: 'pays' })
        }
        if (type === 'fxmaker') {
            this.setState({ displayCharts: 'fxmaker' })
        }
        if (type === 'landinpage') {
            this.setState({ displayCharts: 'landinpage' })
        }
        if (type === 'none') {
            this.setState({ displayCharts: 'none' })
        }
    }

    cique(c) {
        this.state.txtUserPass === c ? this.setState({ roleCreate: true }) : <></>
    }


    render() {
        const { mainDisplay, displayCharts, pathUser } = this.state;

        return (
            <div className="App-header">

                {this.state.roleCreate === false ?

                    <Card>
                        <Cique cique={(e) => { this.cique(e) }} />

                    </Card> : <></>}
                {this.state.roleCreate === true ?
                    <Card className={mainDisplay}>
                        <Row>
                            <Col>
                                <FaGear className="c-pointer z-25" onClick={() => this.toggleDisplay('admin')} />
                            </Col>
                            <Col>
                                <FaDisplay className="c-pointer" onClick={() => this.toggleDisplay('main')} />
                            </Col>
                            <Col>
                                <FaListCheck className="c-pointer" onClick={() => this.toggleDisplay('chart')} />
                            </Col>
                            <Col>
                                <FaMoneyCheckDollar className="c-pointer" onClick={() => this.toggleDisplay('pays')} />
                            </Col>
                            <Col>
                                <FaPaintbrush className="c-pointer" onClick={() => this.toggleDisplay('fxmaker')} />
                            </Col>
                            {<Col>
                                <FaEarthAmericas className="c-pointer" onClick={() => this.toggleDisplay('landinpage')} />
                            </Col>}
                        </Row>
                    </Card>
                    : <></>}
                {displayCharts === 'admin' ? (
                    <FrameAdmin config={this.state} onCrossBtn={() => this.toggleDisplay('none')} />
                ) : displayCharts === 'main' ? (
                    <LoadAdmin onLoadComplete={this.handleDoneInPage} page={pathUser} onCrossBtn={() => this.toggleDisplay('none')} />
                ) : displayCharts === 'chart' ? (
                    <FrameAdminTickets nameSite={pathUser} onCrossBtn={() => this.toggleDisplay('none')} />
                ) : displayCharts === 'pays' ? (
                    <FramePays nameSite={pathUser} onCrossBtn={() => this.toggleDisplay('none')} />
                ) : displayCharts === 'fxmaker' ? (
                    <NFXmaker nameSite={pathUser} onCrossBtn={() => this.toggleDisplay('none')} />
                ) : displayCharts === 'landinpage' ? (
                    <FrameLandinpage nameSite={pathUser} onCrossBtn={() => this.toggleDisplay('none')} />
                ) : null}
                <br />

            </div>
        );
    }
}
