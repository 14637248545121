import React, { Component } from 'react'
import { Button, Card, CardBody, FormControl, InputGroup, Row, Col, Form, CardHeader } from 'react-bootstrap'
import { LoadProduction } from '../../store/MetaStore'
import { Firestore, collection, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage'
import db, { storage } from '../../firebase'
import RemoveBgUploader from './RemoveBgUploader'

export default class FreMonMa extends Component {
  constructor(props) {
    super(props)   
    this.state = LoadProduction.init
  }

  componentDidMount() {

  }

  handleLogin = () => {
    const { user, password } = this.state;

    if (user && password) {
      alert(`Iniciando sesión con:\nUsuario: ${user}\nContraseña: ${password}`);
    } else {
      alert('Por favor, completa todos los campos.');
    }
  };


  crossBtnStore(section) {
    const input = document.getElementById(`input-${section}`);
    if (input) input.click();
  }
  async handleUploadStart(e, section) {
    const file = e.target.files[0];
    if (!file) return;

    const storagePath = `users/Thumbs/maibaik/${this.state.userfistnameBaik}/${this.state.userlastnameBaik}/${this.state.sscid}/${section}.jpg`;
    const storageRef = ref(storage, storagePath);

    const reader = new FileReader();
    reader.onloadend = () => {
      const previewKey = `srcImgMapBack${section.charAt(0).toUpperCase() + section.slice(1)}`;
      this.setState({ [previewKey]: reader.result });
    };
    reader.readAsDataURL(file);
    try {
      await uploadBytes(storageRef, file);
      const downloadUrl = await getDownloadURL(storageRef);

      const urlKey = `srcImgMapBack${section.charAt(0).toUpperCase() + section.slice(1)}`;
      this.setState({ [urlKey]: downloadUrl });
    } catch (error) {
      console.error('Error al subir la imagen:', error);
    }
  }

  toggleImage(section) {
    const toggleKey = `isEnabled${section.charAt(0).toUpperCase() + section.slice(1)}`;
    this.setState((prevState) => ({
      [toggleKey]: !prevState[toggleKey],
    }));
  }

  handleToggleMain() {
    this.state.enableForwardMain ? this.setState({ enableForwardMain: false, }) : this.setState({ enableForwardMain: true, })
  }


  toggleRegister = () => {
    this.setState({ isRegistering: !this.state.isRegistering });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = () => {
    const { user, password, email, isRegistering } = this.state;
    if (isRegistering) {
      alert(`Registrando con email: ${email}`);
    } else {
      alert(`Iniciando sesión con usuario: ${user}`);
    }
  };


  render() {
    const { user, password, email, isRegistering, enableForwardMain, txtUserZoneA, txtUserZoneB, txtUserRegionA, txtUserRegionB } = this.state
    return (
      <div className='App-header t-center w-100 justify-content m-10'>
        <div>
          <Row>
            {enableForwardMain ? <Col>
              <Card className="p-4 shadow-sm mb-4 rounded">
                <h4 className="mb-4 text-center text-primary " style={{ fontFamily: 'cartoon' }}>Registro para Delivery</h4>
                <InputGroup className="mb-3">
                  <FormControl
                    type="text"
                    placeholder="Nombre"
                    className="form-control-lg"
                    onChange={(e) => this.setState({ userfistnameBaik: e.target.value })}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <FormControl
                    type="text"
                    placeholder="Apellido"
                    className="form-control-lg"
                    onChange={(e) => this.setState({ userlastnameBaik: e.target.value })}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <FormControl
                    type="email"
                    placeholder="Correo electrónico"
                    className="form-control-lg"
                    onChange={(e) => this.setState({ usermailBaik: e.target.value })}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <FormControl
                    type="tel"
                    placeholder="Número de celular"
                    className="form-control-lg"
                    onChange={(e) => this.setState({ usercelBaik: e.target.value })}
                  />
                </InputGroup>
                <CardBody className="mt-4">
                  <div className="form-group mb-3">
                    <label className="form-label">Estado:</label>
                    <select
                      className="form-select form-select-lg"
                      value={this.state.txtUserZoneA}
                      onChange={this.handleEstadoChange}
                    >
                      <option value="">Seleccione un estado</option>
                      {this.state.txtUserRegionA.map((estado) => (
                        <option key={estado} value={estado}>
                          {estado}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-3">
                    <label className="form-label">Municipio:</label>
                    <select
                      className="form-select form-select-lg"
                      value={this.state.txtUserZoneB}
                      onChange={this.handleMunicipioChange}
                    >
                      <option value="">Seleccione un municipio</option>
                      {this.state.txtUserRegionB.map((municipio) => (
                        <option key={municipio} value={municipio}>
                          {municipio}
                        </option>
                      ))}
                    </select>
                  </div>

                </CardBody>
              </Card>
            </Col>
              : <Col>
                <Card className='p-4 shadow-sm mb-4 rounded'>
                  <h4 className="mb-4 text-center text-primary" style={{ fontFamily: 'cartoon' }}>Registro para Delivery</h4>
                  {['frontal', 'posterior', 'selfie'].map((section) => {
                    const toggleKey = `isEnabled${section.charAt(0).toUpperCase() + section.slice(1)}`;
                    const previewKey = `srcImgMapBack${section.charAt(0).toUpperCase() + section.slice(1)}`;
                    return (
                      <Card key={section}>
                        <CardBody>
                          <CardHeader>
                            {`INE ${section.charAt(0).toUpperCase() + section.slice(1)}`}
                          </CardHeader>
                          {this.state[toggleKey] ? (
                            <img
                              src={this.state[previewKey] || this.state.sampleImg}
                              alt={`Vista previa de ${section}`}
                              style={{ width: '20%', cursor: 'pointer' }}
                              onClick={() => this.crossBtnStore(section)}
                            />
                          ) : (
                            <p>Imagen deshabilitada</p>
                          )}
                          <Form.Control
                            id={`input-${section}`}
                            style={{ display: 'none' }}
                            type="file"
                            accept="image/png, image/jpg, image/gif"
                            onChange={(e) => this.handleUploadImage(e, section)}
                          />
                          <button
                            onClick={() => this.toggleImage(section)}
                            style={{
                              marginTop: '10px',
                              backgroundColor: this.state[toggleKey] ? 'red' : 'green',
                              color: 'white',
                              border: 'none',
                              padding: '5px 10px',
                              cursor: 'pointer',
                            }}
                          >
                            {this.state[toggleKey] ? 'Deshabilitar' : 'Subir'}
                          </button>
                        </CardBody>
                      </Card>
                    );
                  })}
                  <button className="btn btn-primary btn-lg w-100 mt-4" onClick={this.handleSubmit}>
                    Registrar
                  </button>
                </Card>
              </Col>}
          </Row>
        </div>
        <Button onClick={() => { this.handleToggleMain() }}>{enableForwardMain ? 'Siguiente' : 'Atras'}</Button>
        <br/>
        <RemoveBgUploader/>
      </div >
    )
  }
}
