import React, { Component } from 'react'
import { LoadProduction } from '../store/MetaStore';
import db, { storage } from '../firebase';
import { collection, doc, Firestore, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import { Card, CardBody, CardGroup, Col, Form, Modal, ModalBody, ModalHeader, Row } from 'react-bootstrap';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

export default class FrameTicketSts extends Component {
    constructor(props) {
        super(props)
        this.state = LoadProduction.init
        this.handleEvidenceDone = this.handleEvidenceDone.bind(this)
    }

    obtenerSegundoElemento(cadena) {
        const partes = cadena.split('/');
        return partes[2] || '';
    }

    obtenerTercerElemento(cadena) {
        const partes = cadena.split('/');
        return partes[3] || '';
    }


    obtenerCuartoElemento(cadena) {
        const partes = cadena.split('/');
        return partes[4] || '';
    }

    getStatusColor(status) {
        switch (status.toLowerCase()) {
            case 'pendiente a pago':
                return 'yellow';
            case 'cancelado':
                return 'red';
            case 'error':
                return 'red';
            case 'pagado':
                return 'green';
            case 'completado':
                return 'blue';
            case 'preparando':
                return 'orange';
            default:
                return 'black';
        }
    }


    async componentDidMount() {
        const { pathname } = window.location;
        const cleanedPathname = pathname.startsWith('/tickets/') ? pathname.substring('/tickets/'.length) : pathname;
        const siem = this.obtenerSegundoElemento(pathname);
        const tird = this.obtenerTercerElemento(pathname);
        const fout = this.obtenerCuartoElemento(pathname)
        const license = await doc(db, '$:__site-tickets', tird)
        this.setState({ nameSite: fout })
        if (siem !== '') {
            const isLicenseAvalible = await getDoc(license)
            isLicenseAvalible.exists() ?
                this.setState({
                    ...isLicenseAvalible.data(),
                    pageExist: true,
                    pointUserTicket: isLicenseAvalible.data().pointMonitor.ticketsNow.find(ticket => ticket.idTicket === tird),
                }, async () => {
                    const idTicketsInTicketsNow = isLicenseAvalible.data().pointMonitor.ticketsNow.find(ticket => ticket.idTicket === tird)
                    if (idTicketsInTicketsNow.mptrue === true && fout === 'pagado' && idTicketsInTicketsNow.securePayOn === false) {
                        const updatedTickets = isLicenseAvalible.data().pointMonitor.ticketsNow.map(ticket =>
                            ticket.idTicket === tird
                                ? { ...ticket, status: 'pagado' }
                                : ticket
                        );
                        await updateDoc(license, {
                            'pointMonitor.ticketsNow': updatedTickets
                        });
                    } else {
                        const updatedTickets = isLicenseAvalible.data().pointMonitor.ticketsNow.map(ticket =>
                            ticket.idTicket === tird
                                ? { ...ticket, securePayOn: true }
                                : ticket
                        );
                        await updateDoc(license, {
                            'pointMonitor.ticketsNow': updatedTickets
                        });
                    }
                })
                :
                setTimeout(() => {
                    alert('este sitio no existe'); window.location = '/'
                }, 1500);
        }
    }

    handleUpdateTicket = async (idTicket) => {
        try {
            const { pathname } = window.location;
            const tird = this.obtenerTercerElemento(pathname);
            const licenseRef = doc(db, '$:__site-tickets', tird);
            const licenseSnap = await getDoc(licenseRef);

            const data = licenseSnap.data();
            const { pointMonitor } = data;
            const { ticketsNow } = pointMonitor;



            const updatedTicket = ticketsNow.find(ticket => ticket.id === idTicket);

            if (updatedTicket) {

                const hasChanges = JSON.stringify(this.state.pointUserTicket) !== JSON.stringify(updatedTicket);

                if (hasChanges) {
                    this.setState({
                        pointUserTicket: updatedTicket,
                        pointMonitor: {
                            ...this.state.pointMonitor,
                            ticketsNow
                        }
                    }, () => {

                    });
                } else {

                }
            } else {
                alert('El ticket especificado no se encontró.');
            }

        } catch (error) {

        }
    }



    handleModalStatus(ticket) {

        this.clearUpdateInterval();
        this.setState({
            modalStatusTicket: true,
            selectedTicket: ticket,
            pointStatusTicket: ticket.status,
        });
    }

    handleCloseModal() {

        this.setState({
            modalStatusTicket: false,
            selectedTicket: null,
            pointStatusTicket: '',
            frameConfirmClose: false,
        }, () => {
            if (!this.state.modalStatusTicket) {
                this.startInterval();
            }
        });
    }

    crossBtnItemStore() {
        let putImg = document.querySelectorAll('#itemImg')
        putImg[0].click()
    }

    async getUrlTitle() {
        const { newMessage, pointUserTicket, pointMonitor } = this.state;

        try {
            const url = await getDownloadURL(ref(storage, 'items/Thumbs/' + this.state.siteName + '/' + this.state.sscid + '/ticket-' + this.state.pointNameItem.idTicket + '.jpg'));


            const updatedTicket = {
                ...this.state.pointNameItem,
                imgSrcTracker: url,
            };


            const updatedPointMonitor = {
                ...this.state.pointMonitor,
                ticketsNow: this.state.pointMonitor.ticketsNow.map((ticket) =>
                    ticket.idTicket === pointUserTicket.idTicket ? updatedTicket : ticket
                ),
            };

            const pointMonitorRef = doc(db, '$:__site-tickets', updatedTicket.idTicket);
            await updateDoc(pointMonitorRef, {
                pointMonitor: {
                    ...this.state.pointMonitor,
                    ticketsNow: this.state.pointMonitor.ticketsNow.map((ticket) =>
                        ticket.idTicket === pointUserTicket.idTicket ? updatedTicket : ticket
                    )
                }
            });
        } catch (error) {

        }
    }

    handleTitleImg(c) {
        if (this.state.pointNameItem !== '') {
            let fileData = c.target.files[0];
            const storageRef = ref(storage, 'items/Thumbs/' + this.state.siteName + '/' + this.state.sscid + '/ticket-' + this.state.pointNameItem.idTicket + '.jpg');

            uploadBytes(storageRef, fileData).then(() => {
                this.getUrlTitle();
            }).catch((error) => {

            });
        }
    }

    handleUploadEvidenceModal(i) {
        this.setState({ modalEvidenceUpload: true })
    }
    handleEvidenceDone() {
        this.setState({ modalEvidenceUpload: false })
    }

    async handleSendMessage(e) {
        e.preventDefault();
        await this.handleUpdateTicket()

        const { newMessage, pointUserTicket, pointMonitor } = this.state;
        if (newMessage.trim() === '') return;

        const updatedTicket = {
            ...pointUserTicket,
            chatAndSoportUser: {
                ...pointUserTicket.chatAndSoportUser,
                user: [...(pointUserTicket.chatAndSoportUser.user || []), newMessage],
            },
        };
        const updatedPointMonitor = {
            ...this.state.pointMonitor,
            ticketsNow: this.state.pointMonitor.ticketsNow.map((ticket) =>
                ticket.idTicket === pointUserTicket.idTicket ? updatedTicket : ticket
            ),
        };

        const pointMonitorRef = doc(db, '$:__site-tickets', updatedTicket.idTicket);
        await updateDoc(pointMonitorRef, {
            pointMonitor: {
                ...this.state.pointMonitor,
                ticketsNow: this.state.pointMonitor.ticketsNow.map((ticket) =>
                    ticket.idTicket === pointUserTicket.idTicket ? updatedTicket : ticket
                )
            }
        });
        this.setState({
            pointMonitor: updatedPointMonitor,
            pointUserTicket: updatedTicket,
            newMessage: '',
        });
    }

    render() {
        const { data = {}, idTicket, nameId, status, totalCost, imgSrcTracker } = this.state.pointUserTicket || {};

        return (
            <div className='App'>
                <div className='App-header p-5'>
                    <Row>
                        <Col>
                            <Card className="mb-3" onClick={async () => { await this.handleUpdateTicket(); this.handleUploadEvidenceModal(idTicket) }}>
                                <Card.Body>
                                    <Card.Title>{nameId}</Card.Title>
                                    <CardGroup className="ticket-card">
                                        {Object.entries(data).map(([key, value]) => (
                                            Array.isArray(value) ? (
                                                <table key={key} style={{ fontSize: '0.8em', width: '100%', marginBottom: '1em' }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Para</th>
                                                            <th>Cantidad</th>
                                                            <th>Precio</th>
                                                            <th>Detalles</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {value.map((item, index) => (
                                                            item.keyItems.map((subItem, subIndex) => (
                                                                <tr key={`${key}-${index}-${subIndex}`}>
                                                                    <td>{item.name || 'N/A'}</td>
                                                                    <td>{subItem.clicks || 'N/A'}</td>
                                                                    <td>{subItem.priceItem || 'N/A'}</td>
                                                                    <td>{subItem.keyItem || 'N/A'}</td>
                                                                </tr>
                                                            ))
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <Card.Text key={key} style={{ fontSize: '0.8em' }}>
                                                    <strong>{key}:</strong> {value.name || 'N/A'}<br />
                                                    <strong>Cantidad:</strong> {value.clicks || 'N/A'}
                                                </Card.Text>
                                            )
                                        ))}
                                    </CardGroup>
                                    <Card.Text>
                                        <strong>Total:</strong> ${totalCost}
                                    </Card.Text>
                                    <Card.Text>
                                        <strong>Status:</strong>
                                        <span style={{ color: this.getStatusColor(status), fontSize: '0.9em' }}> {status} </span>
                                    </Card.Text>
                                    <Card.Footer>
                                        <small className="text-muted" style={{ fontSize: '0.8em' }}>ID: {idTicket}</small>
                                    </Card.Footer>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Modal className='w-100 c-items z-modal-con' show={this.state.modalEvidenceUpload} onHide={this.handleEvidenceDone}>
                    <ModalHeader closeButton />
                    <ModalBody>
                        <Card className='z-modal-con'>
                            <CardBody>
                                <p>Cargar comprobante</p>
                              {/*  <img src={imgSrcTracker} width={'33%'} onClick={() => { this.crossBtnItemStore() }} />*/}
                                <Form.Control
                                    style={{ display: 'none' }}
                                    id='itemImg'
                                    type='file'

                                    accept='image/png,image/jpeg'
                                    onChange={(e) => this.handleTitleImg(e)}
                                />
                                <div className="chat-section mt-4">
                                    <h5>Chat con el proveedor</h5>
                                    <div className="chat-box" style={{ maxHeight: '200px', overflowY: 'scroll', border: '1px solid #ccc', padding: '10px' }}>
                                        {this.state.pointUserTicket?.chatAndSoportProvider?.provider.map((msg, index) => (
                                            <div key={index} style={{ marginBottom: '10px' }}>
                                                <strong>Proveedor:</strong> {msg}
                                            </div>
                                        ))}
                                        {this.state.pointUserTicket?.chatAndSoportUser?.user.map((msg, index) => (
                                            <div key={index} style={{ marginBottom: '10px', textAlign: 'right' }}>
                                                <strong>Cliente:</strong> {msg}
                                            </div>
                                        ))}
                                    </div>
                                    <Form className="mt-3" onSubmit={(e) => this.handleSendMessage(e)}>
                                        <Form.Group>
                                            <Form.Control
                                                type="text"
                                                placeholder="Escribe tu mensaje..."
                                                value={this.state.newMessage}
                                                onChange={(e) => this.setState({ newMessage: e.target.value })}
                                            />
                                        </Form.Group>
                                        <button className="btn btn-primary mt-2" type="submit">Enviar</button>
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}