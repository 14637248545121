
import React from 'react';
import NFXmaker from './Common/NFXmaker';


const LoadCreateCodeStaple = () => {
    return (
        <>
            <div className='App-header'>
                <NFXmaker />
            </div>
        </>
    );
};

export default LoadCreateCodeStaple;

