import React, { Component } from 'react'
import { LoadApps } from '../store/MetaStore'
import { auth, provider, signInWithPopup, signOut } from '../firebase';
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import { FaCircleChevronLeft, FaCircleChevronRight, FaSquareFacebook, FaWhatsapp } from 'react-icons/fa6';
import { Firestore, collection, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage'
import db, { storage } from '../firebase';
import AlertMsn from './Common/AlertMsn';
import { FaMapMarkedAlt } from 'react-icons/fa';
import BackgroundUploader from './Common/BackgroundUploader';

export default class FrameAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = this.props.config
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.handleChange = this.handleChange.bind(this)
        this.handleMapsView = this.handleMapsView.bind(this)
        this.handleSetWhats = this.handleSetWhats.bind(this)
        this.handleChangeMain = this.handleChangeMain.bind(this)
    }

    handleBackButtonClick = () => {

        if (this.props.onCrossBtn) {
            this.props.onCrossBtn();
        } else {

        }
    };

    cleanPathname() {
        const { pathname } = window.location;
        const decodedPathname = decodeURIComponent(pathname);
        return decodedPathname.startsWith('/admin/')
            ? decodedPathname.substring('/admin/'.length)
            : decodedPathname;
    }

    async componentDidMount() {

        const { pathname } = window.location;

        const cleanedPathname = this.cleanPathname()
        const license = await doc(db, '$:__sites-name', cleanedPathname)
        this.setState({ nameSite: cleanedPathname })
        if (cleanedPathname !== '') {
            const isLicenseAvalible = await getDoc(license)
            this.state = isLicenseAvalible.data()
            isLicenseAvalible.exists() ?
                this.setState({
                    ...isLicenseAvalible.data(),
                    pageExist: true
                }, () => { this.updateFavicon() })
                :
                setTimeout(() => {
                    alert('este sitio no existe'); window.location = '/'
                }, 1500);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.modalAlert && !prevState.modalAlert) {
            this.autoCloseTimer = setTimeout(() => {
                this.handleCloseAlert();
            }, 1450);
        }

        if (!this.state.modalAlert && prevState.modalAlert) {
            clearTimeout(this.autoCloseTimer);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.autoCloseTimer);
    }

    async handleNewItem() {
        const { modalAlert, txtMainAlert, txtTitleAlert, alertState, ...dataToSave } = this.state;


        this.setState({
            displayCharts: 'none',
            choiseColorBackground: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundImage: `linear-gradient(to ${this.state.pointGradientDirection}, ${this.state.pointColorAlpha}, ${this.state.pointColorBetha})`
            }
        }, async () => {

            try {
                await updateDoc(doc(db, '$:__sites-name', this.state.siteName), this.state);

                this.setState({
                    modalAlert: true,
                    txtMainAlert: 'Configuración emparejada con el servidor',
                    txtTitleAlert: 'Mi sistemita',
                    alertState: 'success',
                });
            } catch (e) {
                this.setState({
                    modalAlert: true,
                    txtMainAlert: 'Error al emparejar con el servidor',
                    txtTitleAlert: 'Mi sistemita',
                    alertState: 'danger',
                });
            }
        });
    }



    handleInputChange = (event) => {
        const newValue = event.target.value;
        this.setState((prevState) => {
            const updatedBtnTxtTitle = [...prevState.btnTxtTitle];
            updatedBtnTxtTitle[6] = newValue;
            return { btnTxtTitle: updatedBtnTxtTitle };
        });
    }

    handleCloseAlert() {
        this.setState({ modalAlert: false });
    };

    handleChange(e) {
        const inputUrl = e.target.value;
        const isFacebook = /^(https?:\/\/)?(www\.)?(facebook\.com)\/[\w\-]+$/;
        if (isFacebook.test(inputUrl)) {
            this.setState({ isValidUrl: true, pointFaceUrl: inputUrl });
        } else {
            this.setState({ isValidUrl: false });
        }
    };

    handleSetWhats(e) {
        const inputUrl = e.target.value;
        this.setState({ isValidWhats: true, pointWhatsUrl: inputUrl });
    }

    handleMapsView(e) {
        const inputUrl = e.target.value;
        const googleMapsRegex = /^(https?:\/\/)?(www\.)?(google\.com\/maps\/place|goo\.gl\/maps|maps\.app\.goo\.gl)\/[\w\-]+$/;
        if (googleMapsRegex.test(inputUrl)) {
            this.setState({ isValidMap: true, pointMapsUrl: inputUrl });
        } else {
            this.setState({ isValidMap: false });
        }
    }


    async handleUrlTitle() {
        const loadResurses = () => {

            try {
                getDownloadURL(ref(storage, 'items/Thumbs/' + this.state.siteName + '/logo.jpg'))
                    .then((url) => {
                        this.setState({
                            logoImg: url,
                            setFavicon: url,
                        }, async () => {

                            const newFavicon = document.createElement('link');
                            newFavicon.id = 'favicon';
                            newFavicon.rel = 'icon';
                            newFavicon.href = this.state.setFavicon;
                            document.head.appendChild(newFavicon);
                        })
                    }).catch((error) => {

                    })
            } catch (e) {

            }

        }
        loadResurses()
    }

    async handleTitleImg(c) {
        const reader = new FileReader();
        let fileData = c.target.files[0]
        reader.onloadend = () => {
            this.setState({ setFavicon: reader.result }, this.updateFavicon(reader.result));
        };
        if (fileData) {
            reader.readAsDataURL(fileData);
        }
        const storageRef = ref(storage, 'items/Thumbs/' + this.state.siteName + '/logo.jpg');
        uploadBytes(storageRef, fileData).then((snapshot) => {
            this.handleUrlTitle()
        })

    }

    crossBtnStore(section) {
        const input = document.getElementById(`input-${section}`);
        if (input) input.click();
    }

    crossBtnItemStore() {
        let putImg = document.querySelectorAll('#itemImg')
        putImg[0].click()
    }

    updateFavicon = (faviconUrl) => {
        let favicon = document.getElementById('favicon');
        if (favicon) {
            favicon.href = faviconUrl;
        } else {
            const newFavicon = document.createElement('link');
            newFavicon.id = 'favicon';
            newFavicon.rel = 'icon';
            newFavicon.href = this.state.setFavicon;
            document.head.appendChild(newFavicon);
        }
    };


    async handleUploadImage(e, section) {
        const file = e.target.files[0];
        if (!file) return;

        const storagePath = `items/Thumbs/${this.state.siteName}/${section}.jpg`;
        const storageRef = ref(storage, storagePath);

        const reader = new FileReader();
        reader.onloadend = () => {
            const previewKey = `srcImgMapBack${section.charAt(0).toUpperCase() + section.slice(1)}`;
            this.setState({ [previewKey]: reader.result });
        };
        reader.readAsDataURL(file);
        try {
            await uploadBytes(storageRef, file);
            const downloadUrl = await getDownloadURL(storageRef);

            const urlKey = `srcImgMapBack${section.charAt(0).toUpperCase() + section.slice(1)}`;
            this.setState({ [urlKey]: downloadUrl });
        } catch (error) {

        }
    }

    toggleImage(section) {
        const toggleKey = `isEnabled${section.charAt(0).toUpperCase() + section.slice(1)}`;
        this.setState((prevState) => ({
            [toggleKey]: !prevState[toggleKey],
        }));
    }

    handleUpdate() {
        const {
            pointBtnGradientDirection,
            pointBtnColorAlpha,
            pointBtnColorBetha,
        } = this.state;

        this.setState({
            choiseBtnColor: {
                fontStyle: "normal",
                backgroundImage: `linear-gradient(to ${pointBtnGradientDirection}, ${pointBtnColorAlpha}, ${pointBtnColorBetha})`,
            },
            choiseColorBackground: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundImage: `linear-gradient(to ${this.state.pointGradientDirection}, ${this.state.pointColorAlpha}, ${this.state.pointColorBetha})`
            }
        })
    }

    handleDirectionChange = (e) => {
        this.setState(
            { pointBtnGradientDirection: e.target.value },
            this.handleUpdate
        );
    };

    handleColor1Change = (e) => {
        this.setState(
            { pointBtnColorAlpha: e.target.value },
            this.handleUpdate
        );
    };

    handleColor2Change = (e) => {
        this.setState(
            { pointBtnColorBetha: e.target.value },
            this.handleUpdate
        );
    };


    handleDirectionChangeB = (e) => {
        this.setState(
            { pointGradientDirection: e.target.value },
            this.handleUpdate
        );
    };

    handleColor1ChangeB = (e) => {
        this.setState(
            { pointColorAlpha: e.target.value },
            this.handleUpdate
        );
    };

    handleColor2ChangeB = (e) => {
        this.setState(
            { pointColorBetha: e.target.value },
            this.handleUpdate
        );
    };

    handleLogin = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            console.log('Usuario autenticado:', user);
            this.setState({ user });
        } catch (error) {
            console.error('Error durante el inicio de sesión:', error);
        }
    };


    handleChangeMain() {
        this.state.enableBackgroundops === true ? this.setState({ enableBackgroundops: false }) : this.setState({ enableBackgroundops: true })
    }

    handleLogout = async () => {
        try {
            await signOut(auth);
            this.setState({ user: null });
            console.log('Sesión cerrada');
        } catch (error) {
            console.error('Error al cerrar sesión:', error);
        }
    };



    render() {

        const {
            choiseColorBackground,
            choiseBtnColor,
            pointBtnGradientDirection,
            pointBtnColorAlpha,
            pointBtnColorBetha,
        } = this.state;


        return (
            <div className='txt-center'>
                <AlertMsn
                    modalAlert={this.state.modalAlert}
                    alertState={this.state.alertState}
                    txtTitleAlert={this.state.txtTitleAlert}
                    txtMainAlert={this.state.txtMainAlert}
                    handleCloseAlert={this.handleCloseAlert}
                />
                <Card className='w-90'>
                    <CardBody>
                        < FaCircleChevronLeft className=' z-10' onClick={() => { this.handleBackButtonClick() }} />
                        <CardHeader>
                            {this.state.nextInsideFrame === false ?
                                <CardTitle>{this.state.txtTitle[1]}</CardTitle>
                                : this.state.lastStep === false ?
                                    <CardTitle>{this.state.txtTitle[2]}</CardTitle> :
                                    <CardTitle>{this.state.txtTitle[3]}</CardTitle>}
                        </CardHeader>
                        <Row className='z-modal p-static'>
                            <Col>
                                <Card style={choiseColorBackground} >
                                    <CardBody>
                                        <div className={` ${this.state.choiseStyleMain}`}>
                                            {this.state.avalibleLoginMain === false ? <Button onClick={this.handleConsole} style={choiseBtnColor} className='z-modal' >{this.state.btnTxtTitle[6]}
                                            </Button> : <> <div className='z-modal'>
                                                {!this.state.user ? (
                                                    <div className='z-modal'>
                                                        <h3 className='color-font'>Inicia sesión con Google</h3>
                                                        <Button style={choiseBtnColor} className='z-modal'>Iniciar sesión</Button>
                                                    </div>
                                                ) : (
                                                    <div className='z-modal'>
                                                        <h1>Bienvenido, {this.state.user.displayName}!</h1>
                                                        <p>Correo: {this.state.user.email}</p>
                                                        <img src={this.state.user.photoURL} alt="Foto de perfil" />
                                                        <button onClick={this.handleLogout}>Cerrar sesión</button>
                                                    </div>
                                                )}
                                            </div></>}
                                            <BackgroundUploader
                                                img1={this.state.isEnabledFull ? this.state.srcImgMapBackFull : this.state.defaultImages.full}
                                                img2={this.state.isEnabledSky ? this.state.srcImgMapBackSky : this.state.defaultImages.sky}
                                                img3={this.state.isEnabledCenter ? this.state.srcImgMapBackCenter : this.state.defaultImages.center}
                                                img4={this.state.isEnabledBase ? this.state.srcImgMapBackBase : this.state.defaultImages.base} />
                                            <footer className='p-absolute bottom w-100 justify-center z-modal-con'>
                                                <Row >
                                                    <Col>
                                                        {this.state.isValidUrl ? (
                                                            <div style={{ bottom: '0', color: 'blue', fontSize: '3.2em' }}>
                                                                <FaSquareFacebook />

                                                            </div>
                                                        ) : (
                                                            <p style={{ bottom: '0', color: 'red', fontSize: '3.2em' }}>Por favor ingrese un URL válido de Facebook</p>
                                                        )}
                                                    </Col>
                                                    <Col>
                                                        {this.state.isValidMap ? (
                                                            <div style={{ bottom: '0', fontSize: '24px', }}>
                                                                <svg width="25" height="25" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <defs>
                                                                        <linearGradient id="googleGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                                                            <stop offset="0%" stopColor="#4285F4" />
                                                                            <stop offset="25%" stopColor="#EA4335" />
                                                                            <stop offset="50%" stopColor="#FBBC05" />
                                                                            <stop offset="75%" stopColor="#34A853" />
                                                                            <stop offset="100%" stopColor="#EA4335" />
                                                                        </linearGradient>
                                                                    </defs>
                                                                    <FaMapMarkedAlt fill="url(#googleGradient)" />
                                                                </svg>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}</Col>
                                                    <Col>
                                                        {this.state.isValidWhats ? (
                                                            <div style={{ bottom: '0', fontSize: '24px', }}>
                                                                <svg width="25" height="25" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <defs>
                                                                        <linearGradient id="whatsGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                                                            <stop offset="0%" stopColor="#2FBC05" />
                                                                            <stop offset="50%" stopColor="#34A853" />
                                                                            <stop offset="75%" stopColor="#34A853" />
                                                                            <stop offset="100%" stopColor="#414335" />
                                                                        </linearGradient>
                                                                    </defs>
                                                                    <FaWhatsapp fill="url(#whatsGradient)" />
                                                                </svg>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </footer>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            {this.state.enableBackgroundops === false ? <> <Col>
                                <Card>
                                    <CardBody>
                                        < FaCircleChevronLeft className=' z-10' onClick={() => { this.handleChangeMain() }} />
                                    </CardBody>
                                </Card>
                                <Card className='change-main-a'>
                                    <CardBody>
                                        <InputGroup>
                                            <FormControl
                                                placeholder='Cambiar Texto de bienvenida'
                                                onChange={this.handleInputChange}
                                            />
                                        </InputGroup>
                                    </CardBody>
                                </Card>

                                <Card className='change-main-a'>
                                    <CardTitle><h6>Cambiar Color de boton</h6></CardTitle>
                                    <CardBody>
                                        <div>
                                            <label>
                                                Dirección de luz:
                                                <select value={pointBtnGradientDirection} onChange={this.handleDirectionChange}>
                                                    <option value="top">Cielo</option>
                                                    <option value="bottom">Suelo</option>
                                                    <option value="left">Izq</option>
                                                    <option value="right">Der</option>
                                                </select>
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                Color 1:
                                                <input
                                                    type="color"
                                                    value={pointBtnColorAlpha}
                                                    onChange={this.handleColor1Change}
                                                />
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                Color 2:
                                                <input
                                                    type="color"
                                                    value={pointBtnColorBetha}
                                                    onChange={this.handleColor2Change}
                                                />
                                            </label>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <Row>
                                        <Col>
                                            <CardBody>
                                                <label>Ingrese el vínculo de Facebook:</label>
                                                <input
                                                    type="text"
                                                    onChange={this.handleChange}
                                                    placeholder={this.state.pointFaceUrl}
                                                />
                                            </CardBody>
                                        </Col>
                                        <Col>
                                            <CardBody>
                                                <label>Ingrese el vínculo de Google maps:</label>
                                                <input
                                                    type="text"
                                                    onChange={this.handleMapsView}
                                                    placeholder={this.state.pointMapsUrl}
                                                />
                                            </CardBody>
                                        </Col>
                                        <Col>
                                            <CardBody>
                                                <label>Ingresa tu whatsapp</label>
                                                <input
                                                    type="text"
                                                    onChange={this.handleSetWhats}
                                                    placeholder={this.state.pointWhatsUrl}
                                                />
                                            </CardBody>
                                        </Col>
                                    </Row>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <CardHeader>Cargar Logo</CardHeader>
                                        <img src={this.state.logoImg} width={'20%'} onClick={() => { this.crossBtnItemStore() }} />
                                        <Form.Control
                                            style={{ display: 'none' }}
                                            id='itemImg'
                                            type='file'
                                            accept='image/png,image/jpg,image/gif'
                                            onChange={(e) => this.handleTitleImg(e)}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            </>
                                : <Col>
                                    <Card>
                                        <CardBody>
                                            < FaCircleChevronRight className=' z-10' onClick={() => { this.handleChangeMain() }} />
                                        </CardBody>
                                        <CardTitle>Area de fondo de pantalla</CardTitle>
                                        <CardBody>
                                            <Row className='p-relative'>
                                                <Col>
                                                    <div>
                                                        <label>
                                                            Dirección de luz:
                                                            <select value={this.state.pointGradientDirection} onChange={this.handleDirectionChangeB}>
                                                                <option value="top">Cielo</option>
                                                                <option value="bottom">Suelo</option>
                                                                <option value="left">Izq</option>
                                                                <option value="right">Der</option>
                                                            </select>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label>
                                                            Color 1:
                                                            <input
                                                                type="color"
                                                                value={this.state.pointColorAlpha}
                                                                onChange={this.handleColor1ChangeB}
                                                            />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label>
                                                            Color 2:
                                                            <input
                                                                type="color"
                                                                value={this.state.pointColorBetha}
                                                                onChange={this.handleColor2ChangeB}
                                                            />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        {['full', 'sky', 'center', 'base'].map((section) => {
                                                            const toggleKey = `isEnabled${section.charAt(0).toUpperCase() + section.slice(1)}`;
                                                            const previewKey = `srcImgMapBack${section.charAt(0).toUpperCase() + section.slice(1)}`;
                                                            return (
                                                                <Card key={section}>
                                                                    <CardBody>
                                                                        <CardHeader>
                                                                            {`Cargar ${section.charAt(0).toUpperCase() + section.slice(1)}`}
                                                                        </CardHeader>
                                                                        {this.state[toggleKey] ? (
                                                                            <img
                                                                                src={this.state[previewKey] || this.state.sampleImg}
                                                                                alt={`Vista previa de ${section}`}
                                                                                style={{ width: '20%', cursor: 'pointer' }}
                                                                                onClick={() => this.crossBtnStore(section)}
                                                                            />
                                                                        ) : (
                                                                            <p>Imagen deshabilitada</p>
                                                                        )}
                                                                        <Form.Control
                                                                            id={`input-${section}`}
                                                                            style={{ display: 'none' }}
                                                                            type="file"
                                                                            accept="image/png, image/jpg, image/gif"
                                                                            onChange={(e) => this.handleUploadImage(e, section)}
                                                                        />
                                                                        <button
                                                                            onClick={() => this.toggleImage(section)}
                                                                            style={{
                                                                                marginTop: '10px',
                                                                                backgroundColor: this.state[toggleKey] ? 'red' : 'green',
                                                                                color: 'white',
                                                                                border: 'none',
                                                                                padding: '5px 10px',
                                                                                cursor: 'pointer',
                                                                            }}
                                                                        >
                                                                            {this.state[toggleKey] ? 'Deshabilitar' : 'Habilitar'}
                                                                        </button>
                                                                    </CardBody>
                                                                </Card>
                                                            );
                                                        })}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>}
                        </Row>
                        <Row className='z-modal-con p-relative'>
                            <Col> <div className='c-items' >
                                <Form className='w-270' >
                                    {['radio'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                            <Form.Check
                                                inline
                                                label="1"
                                                name="group1"
                                                type={type}
                                                id={`inline-${type}-1`}
                                                defaultChecked={false}
                                                onChange={() => { this.setState({ choiseStyleMain: 'b-items', avalibleLoginMain: true }) }}
                                            />
                                            <Form.Check
                                                inline
                                                label="2"
                                                name="group1"
                                                type={type}
                                                id={`inline-${type}-2`}
                                                defaultChecked={true}
                                                style={{ marginLeft: '5rem' }}
                                                onChange={() => { this.setState({ choiseStyleMain: 'c-items', avalibleLoginMain: false }) }}
                                            />
                                        </div>
                                    ))}
                                </Form>
                            </div>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Button onClick={() => { this.handleNewItem() }}>Aplicar Cambios </Button>
                    </CardFooter>
                </Card>
            </div >
        )
    }
}
