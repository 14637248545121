import React, { Component } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import db from '../firebase';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parseISO } from 'date-fns';
import { Card, CardBody, Modal, ModalBody } from 'react-bootstrap';

class FrameAdminTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closedTickets: [],
      filteredTickets: [],
      isLoading: true,
      error: null,
      filterDate: null,
      showModal: false,
      selectedTicket: null,
    };
  }

  componentDidMount() {
    this.fetchClosedTickets();
  }

  cleanPathname() {
    const { pathname } = window.location;
    const decodedPathname = decodeURIComponent(pathname);
    return decodedPathname.startsWith('/admin/')
      ? decodedPathname.substring('/admin/'.length)
      : decodedPathname;
  }

  async fetchClosedTickets() {
    const siteName = this.cleanPathname();
    try {
      const closedTicketsRef = collection(db, '$:__site-close');
      const q = query(closedTicketsRef, where('siteName', '==', siteName));
      const querySnapshot = await getDocs(q);

      const closedTickets = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      this.setState({
        closedTickets,
        filteredTickets: closedTickets,
        isLoading: false,
      });
    } catch (error) {    
      this.setState({ error: error.message, isLoading: false });
    }
  }

  parseCustomDate(dateString) {
    try {
      const [date, time, period] = dateString.split(/, | /);
      const [day, month, year] = date.split('/').map(Number);
      const [hours, minutes, seconds] = time.split(':').map(Number);

      const adjustedHours = period.toLowerCase() === 'p.m.' && hours !== 12 ? hours + 12 : hours;

      return new Date(year, month - 1, day, adjustedHours, minutes, seconds);
    } catch (error) {      
      return null;
    }
  }

  handleDateFilter = (date) => {
    const { closedTickets } = this.state;
    this.setState({ filterDate: date });

    if (date) {
      const filteredTickets = closedTickets.filter((ticket) => {
        const ticketDate =
          ticket.closedAt?.toDate?.() ||
          (typeof ticket.closedAt === 'string' && this.parseCustomDate(ticket.closedAt))

        return (
          ticketDate &&
          ticketDate.toISOString().split('T')[0] === date.toISOString().split('T')[0]
        );
      });

      this.setState({ filteredTickets });
    } else {
      this.setState({ filteredTickets: closedTickets });
    }
  };

  viewTicketDetails = (ticket) => {
    this.setState({
      showModal: true,
      selectedTicket: { ...ticket, pointMonitor: 'sa' },
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      selectedTicket: null,
    });
  };

  handleMaxTicketsChange = (event) => {
    this.setState({
      maxTicketsPerPage: parseInt(event.target.value, 10),
      currentPage: 1,
    });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  getPaginatedTickets = () => {
    const { filteredTickets, currentPage, maxTicketsPerPage } = this.state;
    const startIndex = (currentPage - 1) * maxTicketsPerPage;
    const endIndex = startIndex + maxTicketsPerPage;
    return filteredTickets.slice(startIndex, endIndex);
  };


  render() {
    const { filteredTickets, isLoading, error, filterDate, showModal, selectedTicket } = this.state;

    if (isLoading) {
      return <div>Loading closed tickets...</div>;
    }

    if (error) {
      return <div>Error: {error}</div>;
    }

    return (

      <div className="frame-admin-ticket">
        <h1>Tickets antiguos - {this.cleanPathname()}</h1>
        <div className="filter-section">
          <h3>Calendario</h3>
          <DatePicker
            selected={this.state.filterDate}
            onChange={this.handleDateFilter}
            dateFormat="yyyy-MM-dd"
            placeholderText="Select a date"
          />
        </div>

        <div className="pagination-controls">
          <label htmlFor="max-tickets-select">Mostrar:</label>
          <select
            id="max-tickets-select"
            value={this.state.maxTicketsPerPage}
            onChange={this.handleMaxTicketsChange}
          >
            <option value={13}>13</option>
            <option value={22}>22</option>
          </select>
        </div>

        {filteredTickets.length === 0 ? (
          <>
            <p>No has vendido nada aun o no le picaste bien en cerrar xD.</p>
            <p>verifica en tu sitio <span>https://misistemita.store/{this.state.siteName}</span></p>
          </>
        ) : (
          <table className="closed-tickets-table">
            <thead>
              <tr>
                <th>Ticket</th>
                <th>Status</th>
                <th>Dia de venta</th>
                <th>Detalles</th>
              </tr>
            </thead>
            <tbody>
              {this.getPaginatedTickets().map((ticket) => {
                let closedAtFormatted = 'N/A';
                const ticketDate =
                  ticket.closedAt?.toDate?.() ||
                  (typeof ticket.closedAt === 'string' && this.parseCustomDate(ticket.closedAt));

                if (ticketDate) {
                  closedAtFormatted = format(ticketDate, 'dd/MM/yyyy HH:mm');
                }

                return (
                  <tr key={ticket.id}>
                    <td>{ticket.id}</td>
                    <td>{ticket.status}</td>
                    <td>{closedAtFormatted}</td>
                    <td>
                      <button onClick={() => this.viewTicketDetails(ticket)}>View</button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        {filteredTickets.length > this.state.maxTicketsPerPage && (
          <div className="pagination">
            {Array.from(
              { length: Math.ceil(filteredTickets.length / this.state.maxTicketsPerPage) },
              (_, i) => i + 1
            ).map((pageNumber) => (
              <button
                key={pageNumber}
                className={this.state.currentPage === pageNumber ? 'active' : ''}
                onClick={() => this.handlePageChange(pageNumber)}
              >
                {pageNumber}
              </button>
            ))}
          </div>
        )}


        {/* Modal */}

        {selectedTicket && (
          <Modal show={showModal} onHide={this.closeModal}>
            <ModalBody>
              <Card>
                <CardBody>
                  <h2>Info Ticket </h2>
                  <p><strong>ID:</strong> {selectedTicket.id}</p>
                  <p><strong>Status:</strong> {selectedTicket.status}</p>
                  <p><strong>Vendido:</strong> {selectedTicket.closedAt}</p>
                  <p><strong>Historial:</strong> {selectedTicket.pointMonitor.ticketsNow || 'N/A'}</p>
                  <button onClick={this.closeModal}>Close</button>
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>
        )}
      </div>

    );

  }

}

export default FrameAdminTicket;
