import React, { Component } from 'react'
import GIF from 'gif.js';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, setDoc } from 'firebase/firestore';
import db  from '../../firebase'; 

class GifGenerator extends React.Component {
    state = {
        imageArray: [],
        sourceMapGif: {},
        siteName: 'sample-site', 
        pointAnimationName: 'animation1',
        pointNameItem: 'example-item',
        pointDelay: 500,
        sscid: 'unique-id',
        gifURL: null,
    };

    async generateGif() {
        const { imageArray, sourceMapGif, siteName, pointAnimationName, pointNameItem, pointDelay, sscid } = this.state;

        if (!imageArray.length) {
           
            return;
            
        }
        const gif = new GIF({
            workers: 2,
            quality: 10,
            transparent: 'rgba(0, 255, 0, 0)', 
        });

        imageArray.forEach((image) => {
            const img = new Image();
            img.src = image;
            gif.addFrame(img, { delay: pointDelay });
        });

        gif.on('finished', async (blob) => {  
            const storage = getStorage();
            const storageRef = ref(storage, `items/Thumbs/${siteName}/${sscid}/${pointNameItem}.gif`);
            const uploadResult = await uploadBytes(storageRef, blob);

            const downloadURL = await getDownloadURL(storageRef); 
            const updatedMap = {
                ...sourceMapGif,
                [pointAnimationName]: {
                    gifURL: downloadURL,
                    nameGif: pointAnimationName,
                    category: '',
                    privacity: 'private',
                    creationDay: new Date().toISOString(),
                },
            };

            const licenseRef = doc(db, '$:__sites-name', siteName);
            await setDoc(licenseRef, { sourceMapGif: updatedMap }, { merge: true });

            this.setState({ sourceMapGif: updatedMap, gifURL: downloadURL }, () => {
              
            });
        });

        gif.render();
    }

    render() {
        return (
            <div>
                <button onClick={() => this.generateGif()}>Generar GIF</button>
                {this.state.gifURL && <img src={this.state.gifURL} alt="GIF generado" />}
            </div>
        );
    }
}

export default GifGenerator;