import React from "react";

const BackgroundUploader = ({ img1, img2, img3, img4,choiseColorBackground }) => {
    return (
        <div className={"App-header "} style={choiseColorBackground}>
            {img1 && (
                <div 
                    style={{
                        backgroundImage: `url(${img1})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 0,
                    }}
                />
            )}
            {img2 && (
                <div
                    style={{
                        backgroundImage: `url(${img2})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "33.33%",
                        zIndex:50,
                    }}
                />
            )}
            {img3 && (
                <div 
                    style={{
                        backgroundImage: `url(${img3})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        position: "absolute",
                        top: "33.33%",
                        left: 0,
                        width: "100%",
                        height: "33.33%",
                        zIndex: 80,
                    }}
                />
            )} 
            {img4 && (
                <div
                    style={{
                        backgroundImage: `url(${img4})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        position: "absolute",
                        bottom:0,
                        left: 0,
                        width: "100%",
                        height: "33.33%",
                        zIndex: 100,
                    }}
                />
            )}
        </div>
    );
};

export default BackgroundUploader;
