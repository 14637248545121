import React, { Component } from 'react';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import db, { storage } from '../../firebase';
import { FaEraser, FaPaintBrush } from 'react-icons/fa';
import { Button, Card, CardBody, Col, Row } from 'react-bootstrap'; import { SketchPicker } from 'react-color';
import axios from 'axios'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import gifshot from 'gifshot';

class NFXmaker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            strokeSize: 1,
            sprites: [],
            currentFrame: 0,
            myAnimations: {},
            pointAnimationName: '',
            isAnimating: false,
            drawingColor: '#000000',
            frameInterval: 300,
            localAnimations: [],
            pointDelay: 500,
            gifURL: '',
            sourceMapGif: '',
        };
        this.isDrawing = false;
        this.canvasRef = React.createRef();
    }


    async componentDidMount() {

        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext('2d');
        this.ctx = ctx;
        canvas.addEventListener('mousedown', this.handleMouseDown);
        canvas.addEventListener('mousemove', this.handleMouseMove);
        canvas.addEventListener('mouseup', this.handleMouseUp);
        canvas.addEventListener('mouseleave', this.handleMouseUp);
        canvas.addEventListener('touchstart', this.handleTouchStart);
        canvas.addEventListener('touchmove', this.handleTouchMove);
        canvas.addEventListener('touchend', this.handleTouchEnd);
        canvas.addEventListener('touchcancel', this.handleTouchEnd);

        const { pathname } = window.location;
        const cleanedPathname = pathname.startsWith('/admin/') ? pathname.substring('/admin/'.length) : pathname;
        this.setState({ nameSite: cleanedPathname });

        if (cleanedPathname !== '') {
            const licenseRef = doc(db, '$:__sites-name', cleanedPathname);
            const licenseDoc = await getDoc(licenseRef);
            if (licenseDoc.exists()) {
                const data = licenseDoc.data();
                this.setState({ ...data, pageExist: true }, () => {

                    this.handleSourceGif(data.sourceMapGif);

                });
            } else {
                setTimeout(() => {
                    alert('Este sitio no existe');
                    window.location = '/';
                }, 1500);
            }
        }
    }


    loadSavedSprites = async (savedSprites) => {
        const updatedSprites = savedSprites.map((spriteData) => {
            if (spriteData.data && spriteData.data.length === spriteData.width * spriteData.height * 4) {
                return new ImageData(
                    new Uint8ClampedArray(spriteData.data),
                    spriteData.width,
                    spriteData.height
                );
            }
            return null;
        });

        this.setState({ sprites: updatedSprites }, () => {
            const { currentFrame } = this.state;
            if (updatedSprites[currentFrame]) {
                this.ctx.putImageData(updatedSprites[currentFrame], 0, 0);
            }
        });
        await this.handleSaveSprite();
    };

    handleMouseDown = (e) => {
        this.isDrawing = true;
        this.draw(e);
    };

    handleMouseMove = (e) => {
        if (!this.isDrawing) return;
        this.draw(e);
    };

    handleMouseUp = () => {
        this.isDrawing = false;
    };

    handleTouchStart = (e) => {
        e.preventDefault();
        this.isDrawing = true;
        this.drawTouch(e);
    };

    handleTouchMove = (e) => {
        if (!this.isDrawing) return;
        this.drawTouch(e);
    };

    handleTouchEnd = () => {
        this.isDrawing = false;
    };

    draw = (e) => {
        const ctx = this.ctx;
        const rect = this.canvasRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        if (this.state.isErasing) {
            ctx.clearRect(x, y, 1, 1);
        } else {
            ctx.fillStyle = this.state.drawingColor;
            ctx.fillRect(x, y, this.state.strokeSize, this.state.strokeSize);
        }

        this.saveCurrentFrame();
    };

    drawTouch = (e) => {
        const ctx = this.ctx;
        const rect = this.canvasRef.current.getBoundingClientRect();
        const touch = e.touches[0];
        const x = touch.clientX - rect.left;
        const y = touch.clientY - rect.top;

        if (this.state.isErasing) {
            ctx.clearRect(x, y, 1, 1);
        } else {
            ctx.fillStyle = this.state.drawingColor;
            ctx.fillRect(x, y, this.state.strokeSize, this.state.strokeSize);
        }

        this.saveCurrentFrame();
    };


    loadSavedAnimations = (animations) => {
        const parsedAnimations = {};
        Object.keys(animations).forEach((name) => {
            parsedAnimations[name] = animations[name].map((spriteData) => {
                if (spriteData.data) {
                    return new ImageData(
                        new Uint8ClampedArray(spriteData.data),
                        spriteData.width,
                        spriteData.height
                    );
                }
                return null;
            });
        });
        this.setState({ myAnimations: parsedAnimations });
    };

    saveCurrentFrame = () => {
        const { currentFrame, sprites } = this.state;
        const updatedSprites = [...sprites];
        updatedSprites[currentFrame] = this.ctx.getImageData(0, 0, 254, 254);
        this.setState({ sprites: updatedSprites });
    };

    handleAddFrame = () => {
        const { sprites } = this.state;
        const newSprites = [...sprites, null];
        this.setState({ sprites: newSprites, currentFrame: newSprites.length - 1 });
        this.ctx.clearRect(0, 0, 254, 254);
    };

    handleRemoveFrame = () => {
        const { sprites, currentFrame } = this.state;

        if (sprites.length <= 1) {
            alert('No puedes eliminar el único frame.');
            return;
        }

        const updatedSprites = sprites.filter((_, index) => index !== currentFrame);
        const newFrameIndex = Math.max(0, currentFrame - 1);

        this.setState({ sprites: updatedSprites, currentFrame: newFrameIndex }, () => {
            if (updatedSprites[newFrameIndex]) {
                this.ctx.putImageData(updatedSprites[newFrameIndex], 0, 0);
            } else {
                this.ctx.clearRect(0, 0, 254, 254);
            }
        });
    };

    handleSelectFrame = (frameIndex) => {
        const { sprites } = this.state;
        this.setState({ currentFrame: frameIndex }, () => {
            if (sprites[frameIndex]) {
                this.ctx.putImageData(sprites[frameIndex], 0, 0);
            } else {
                this.ctx.clearRect(0, 0, 254, 254);
            }
        });
    };

    handleSaveAnimation = async () => {
        const { pointAnimationName, sprites, myAnimations, sourceMapGif } = this.state;

        if (!pointAnimationName) {
            alert('Por favor, introduce un nombre para la animación');
            return;
        }

        if (sourceMapGif && sourceMapGif[pointAnimationName]) {
            alert('El nombre de la animación ya existe. Por favor, elige otro nombre.');
            return;
        }

        const serializedSprites = sprites.map((sprite) => ({
            data: sprite ? Array.from(sprite.data) : [],
            width: sprite ? sprite.width : 254,
            height: sprite ? sprite.height : 254,
        }));

        const newAnimation = {
            name: pointAnimationName,
            frames: serializedSprites,
        };

        const updatedAnimations = {
            ...myAnimations,
            [pointAnimationName]: serializedSprites,
        };

        this.setState({ myAnimations: updatedAnimations })

        this.setState(
            (prevState) => ({
                localAnimations: [...prevState.localAnimations, newAnimation],
            }),
            async () => {
                alert(`Animación "${pointAnimationName}" guardada temporalmente.`);

                await this.createGif();

                await this.generateGif();
            }
        );
    };

    handleAnimationToggle = () => {
        this.setState((prevState) => ({ isAnimating: !prevState.isAnimating }), this.animateFrames);
    };

    animateFrames = () => {
        const { isAnimating, sprites, currentFrame, frameInterval } = this.state;
        if (!isAnimating) return;

        const nextFrame = (currentFrame + 1) % sprites.length;
        this.ctx.clearRect(0, 0, 254, 254);
        if (sprites[nextFrame]) {
            this.ctx.putImageData(sprites[nextFrame], 0, 0);
        }
        this.setState({ currentFrame: nextFrame });
        setTimeout(this.animateFrames, frameInterval);
    };

    handleAnimationNameChange = (e) => {
        this.setState({ pointAnimationName: e.target.value });
    };


    toggleEraser = () => {
        this.setState({ isErasing: !this.state.isErasing });
    };

    handleColorChange = (color) => {
        this.setState({ drawingColor: color.hex });
    };

    increaseStrokeSize = () => {
        this.setState((prevState) => ({ strokeSize: prevState.strokeSize + 1 }));
    };

    decreaseStrokeSize = () => {
        this.setState((prevState) => ({
            strokeSize: Math.max(prevState.strokeSize - 1, 1),
        }));
    };

    handleDeleteLocalAnimation = (animationName) => {
        this.setState((prevState) => ({
            localAnimations: prevState.localAnimations.filter(
                (animation) => animation.name !== animationName
            ),
        }));
    };

    crossBtnItemStore() {
        let putImg = document.querySelectorAll('#itemImg')
        putImg[0].click()
    }

    async getUrlTitle() {
        const loadResurses = () => {
            try {
                getDownloadURL(ref(storage, 'items/Thumbs/' + this.state.siteName + '/' + this.state.sscid + '/' + this.state.pointAnimationName + '.gif'))
                    .then((url) => {
                        this.setState({
                            noImg: url,
                            sampleImg: url,
                        })
                    }).catch((error) => {

                    })
            } catch (e) {

            }
        }
        loadResurses()
    }

    handleTitleImg(c) {
        if (this.state.pointNameItem !== '') {
            let fileData = c.target.files[0]
            const storageRef = ref(storage, 'items/Thumbs/' + this.state.siteName + '/' + this.state.sscid + '/' + this.state.pointNameItem + '.gif');
            uploadBytes(storageRef, fileData).then((snapshot) => {
                this.getUrlTitle()
            })
        }
    }

    async generateGif() {
        const { imageArray, sourceMapGif } = this.state;
        const payload = {
            imageSrcs: imageArray,
            width: 500,
            height: 500,
            delay: this.state.pointDelay
        }

        try {
            const response = await axios.post('https://backensinfree4a.online/generate-gif', payload);
            const gifUrl = response.data.gifSrc;
            const gifResponse = await axios.get(process.env.REACT_APP_SRC_GIF, { responseType: 'blob' });
            const gifBlob = gifResponse.data;
            const storage = getStorage();
            const storageRef = ref(storage, `items/Thumbs/${this.state.siteName}/gif/${this.state.sscid}/${this.state.pointAnimationName}.gif`);
            const uploadResult = await uploadBytes(storageRef, gifBlob);
            const downloadURL = await getDownloadURL(storageRef);
            const creationDate = new Date().toISOString();
            const newGifData = {
                gifURL: downloadURL,
                nameGif: this.state.pointAnimationName,
                category: '',
                privacity: 'private',
                cost: 0,
                createBy: this.state.siteName,
                sayTi: '',
                creationDay: creationDate
            };
            const updatedSourceMapGif = { ...sourceMapGif };
            updatedSourceMapGif[this.state.pointAnimationName] = newGifData;

            const licenseRef = doc(db, `$:__sites-name`, this.state.siteName);
            await setDoc(licenseRef, { sourceMapGif: updatedSourceMapGif }, { merge: true });

            this.setState({
                gifURL: downloadURL,
                sourceMapGif: updatedSourceMapGif,
                pointAnimationName: '',
            }, () => {
            });

        } catch (error) {
          
        }
    }


    createGif() {
        this.state.sprites.forEach((sprite, index) => {
            if (sprite) {
                const canvas = document.createElement("canvas");
                canvas.width = 254;
                canvas.height = 254;
                const ctx = canvas.getContext("2d");
                ctx.putImageData(sprite, 0, 0);
                const img = new Image();
                img.src = canvas.toDataURL("image/png");
                this.setState((prevState) => ({
                    imageArray: [...(prevState.imageArray || []), img.src],
                }));
            }
        })
    }

    async generateGifx() {
        const { imageArray, sourceMapGif, siteName, pointAnimationName, pointNameItem, pointDelay, sscid } = this.state;

        if (!imageArray.length) {
          
            return;
        }

        try {
            const canvas = document.createElement('canvas');
            canvas.width = 500;
            canvas.height = 500;
            const ctx = canvas.getContext('2d');
            const processedImages = await Promise.all(
                imageArray.map((src) => this.processImageWithTransparency(src, canvas, ctx))
            );
            gifshot.createGIF(
                {
                    images: processedImages,
                    gifWidth: 500,
                    gifHeight: 500,
                    interval: pointDelay / 1000,
                    transparent: true,
                    numWorkers: 2,
                    quality: 10,
                },
                async (obj) => {
                    if (!obj.error) {
                        const gifBlob = obj.image;
                        const blobData = this.dataURItoBlob(gifBlob);

                        const storage = getStorage();
                        const storageRef = ref(storage, `items/Thumbs/${siteName}/${sscid}/${pointNameItem}.gif`);
                        await uploadBytes(storageRef, blobData);

                        const downloadURL = await getDownloadURL(storageRef);

                        const updatedMap = {
                            ...sourceMapGif,
                            [pointAnimationName]: {
                                gifURL: downloadURL,
                                nameGif: pointAnimationName,
                                category: '',
                                privacity: 'private',
                                creationDay: new Date().toISOString(),
                            },
                        };

                        const licenseRef = doc(db, '$:__sites-name', siteName);
                        await setDoc(licenseRef, { sourceMapGif: updatedMap }, { merge: true });

                        this.setState({ sourceMapGif: updatedMap, gifURL: downloadURL }, () => {
                        });
                    } else {
                   
                    }
                }
            );
        } catch (error) {
           
        }
    }

    async processImageWithTransparency(src, canvas, ctx) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.crossOrigin = 'anonymous';
            image.onload = () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                ctx.fillStyle = 'rgba(0, 0, 0, 0)';
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

                resolve(canvas.toDataURL());
            };
            image.onerror = reject;
            image.src = src;
        });
    }

    dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    handleSourceGif = (sourceMapGif) => {
        const animations = Object.keys(sourceMapGif).map((key) => ({
            id: key,
            ...sourceMapGif[key],
        }));
        this.setState({ myAnimations: animations });
    };

    render() {
        const { sprites, currentFrame, isAnimating, pointAnimationName, sourceMapGif, drawingColor, isErasing, localAnimations } = this.state;

        return (
            <div className='font-size-2 justify-center'>
                <h3>Editor FX</h3>
                <div>
                    <h4></h4>
                    <ul>
                        {(localAnimations || []).map((animation) => (
                            <li key={animation.name}>
                                {animation.name}{' '}
                                <Button onClick={() => this.handleDeleteLocalAnimation(animation.name)}>
                                    Eliminar
                                </Button>
                            </li>
                        ))}
                    </ul>
                </div>
                <Row>
                    <Col>
                        <canvas
                            ref={this.canvasRef}
                            width={348}
                            height={254}
                            style={{ border: '1px solid black' }}
                        />
                        {this.state.gifURL !== '' ? <img src={this.state.gifURL} width={'100%'} /> : null}
                    </Col>
                    <Col>
                        <div>
                            <Card>
                                <CardBody>
                                    <button onClick={this.toggleEraser}>
                                        {isErasing ? (
                                            <>
                                                <FaEraser /> Borrador Activado
                                            </>
                                        ) : (
                                            <>
                                                <FaPaintBrush /> Borrador Desactivado
                                            </>
                                        )}
                                    </button>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <button onClick={this.decreaseStrokeSize}>-</button>
                                    <span className='font-size-2'>Tamaño de trazo: {this.state.strokeSize}</span>
                                    <button onClick={this.increaseStrokeSize}>+</button>
                                </CardBody>
                            </Card>
                        </div>
                        <div >
                            <SketchPicker
                                
                                color={drawingColor}
                                onChange={this.handleColorChange}
                                disableAlpha={true}
                            />
                        </div>
                    </Col>
                </Row>

                <div>                
                    <Button onClick={this.handleAddFrame}>Añadir Frame</Button>
                    <Button onClick={this.handleRemoveFrame}>Eliminar Frame</Button>
                    <Button onClick={this.handleAnimationToggle}>
                        {isAnimating ? 'Detener Animación' : 'Iniciar Animación'}
                    </Button>
                </div>
                <div>
                    <h4>Frames</h4>
                    {sprites.map((_, index) => (
                        <Button
                            key={index}
                            onClick={() => this.handleSelectFrame(index)}
                            style={{
                                backgroundColor: currentFrame === index ? 'yellow' : 'white',
                            }}
                        >
                            Frame {index + 1}
                        </Button>
                    ))}
                </div>
                <div>
                    <input
                        type="text"
                        placeholder="Nombre de la animación"
                        value={pointAnimationName}
                        onChange={this.handleAnimationNameChange}
                    />
                    <Button onClick={this.handleSaveAnimation}>Guardar Animación</Button>
                </div>
                <div className='font-size-2 justify-center w-100'>
                    <h4>Mis Animaciones</h4>
                    <Row className='font-size-2 justify-center'>
                        {Object.keys(sourceMapGif).map((key) => {
                            const animation = sourceMapGif[key];
                            return (
                                <Col md={3} key={key}>
                                    <Card className="card">
                                        <img
                                            src={animation.gifURL}
                                            className="card-img-top"
                                            alt={animation.nameGif || key}
                                        />
                                        <CardBody className="card-body">
                                            <h5 className="card-title">
                                                {animation.nameGif || key}
                                            </h5>
                                            <p className="card-text">
                                                Creado por: {animation.createBy || "Desconocido"}
                                            </p>
                                            <p className="card-text">
                                                Día de creación:{" "}
                                                {animation.creationDay
                                                    ? new Date(animation.creationDay).toLocaleDateString()
                                                    : "No especificado"}
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            </div>
        );
    }
}

export default NFXmaker;
