import React, { useState, useEffect, useImperativeHandle, forwardRef, ref } from 'react';
import { Button } from 'react-bootstrap';
import { useMercadopago } from 'react-sdk-mercadopago';
import axios from 'axios';

const MercadoPagoBtn = forwardRef(({ nameId, ssuid, metaObject, totalCost, nameApp, accessToken, publicKey }, ref) => {
  const [takeId, setTakeId] = useState("");
  const [titleCard, setTitleCard] = useState(nameId);
  const [unitCts, setUnitCts] = useState(totalCost);
  const [codeId, setCodeId] = useState(ssuid);
  const [nameApps, setNameApp] = useState(nameApp);
  const [initPoint, setInitPoint] = useState('')
  const [showModal, setShowModal] = useState(false)
  const mercadopago = useMercadopago.v2(publicKey, {
    locale: 'es-MX'
  });

  useEffect(() => {
    if (ssuid !== codeId) {
      setCodeId(ssuid);
    }
  }, [ssuid]);

  useEffect(() => {
    if (!takeId) return;  
    setShowModal(true)
  }, [mercadopago, takeId]);

  const handlePay = async () => {

    try {
      const response = await axios.post('https://backensinfree4a.online/create_preference', {
        access_token: accessToken,
        sandbox: true,
        quantity: 1,
        description: titleCard,
        price: unitCts + 4 + (0.031 * unitCts),
        codeTraker: codeId,
        nameAppx: nameApps
      });
      setTakeId(response.data.id);
      setInitPoint(response.data.init_point)
    } catch (error) {
      
    }

  };


  useImperativeHandle(ref, () => ({
    triggerPayment: handlePay
  }));

  const simpleStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
    },
    modal: {
      backgroundColor: '#fff',
      borderRadius: '10px',
      width: '80%',
      maxWidth: '900px',
      padding: '20px',
      position: 'relative',
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      background: 'none',
      border: 'none',
      fontSize: '18px',
      cursor: 'pointer',
    },
    iframe: {
      width: '100%',
      height: '600px',
      border: 'none',
    },
  };

  const closeModal = () => {
    setShowModal(false)
  }

  return (

    <>
      <div className='no-show'>
        <Button variant="outline-info" onClick={handlePay} size="lg">Mercadopago</Button>
      </div>
      {showModal && initPoint && (
        <div className='z-modal' style={simpleStyles.overlay}>
          <div style={simpleStyles.modal}>
            <button onClick={closeModal} style={simpleStyles.closeButton}>
              ✖
            </button>
            <iframe
              src={initPoint}
              style={simpleStyles.iframe}
              title="MercadoPago Checkout"
            />
          </div>
        </div>
      )}

    </>
  );
});

export default MercadoPagoBtn;