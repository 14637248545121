import { doc, getDoc, setDoc, updateDoc, collection, getDocs, deleteDoc, } from 'firebase/firestore';
import React, { Component } from 'react';
import { Card, Col, Form, Modal, Row, Button, Offcanvas, CardGroup, CardBody } from 'react-bootstrap';
import db from '../firebase';
import { LoadTicketCreation } from '../store/MetaStore';
import Cique from './Cique/Cique';

export default class LoadMonitorFrame extends Component {
  constructor(props) {
    super(props);
    this.state = LoadTicketCreation.init;
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }

  cleanPathname() {
    const { pathname } = window.location;
    const decodedPathname = decodeURIComponent(pathname);
    return decodedPathname.startsWith('/monitor/')
      ? decodedPathname.substring('/monitor/'.length)
      : decodedPathname;
  }

  componentDidMount = async () => {
    const { pathname } = window.location;
    const cleanedPathname = this.cleanPathname()

    const license = await doc(db, '$:__sites-name', cleanedPathname)
    if (cleanedPathname !== '') {
      const isLicenseAvalible = await getDoc(license)
      isLicenseAvalible.exists() ?
        this.setState({
          ...isLicenseAvalible.data(),
          pageExist: true
        }, async () => {

          const ticketsSnapshot = await getDocs(collection(db, '$:__site-tickets'));
          const ticketsNow = [];
          ticketsSnapshot.forEach((doc) => {
            const ticketData = doc.data();
            if (ticketData.siteName && ticketData.siteName === this.state.siteName) {
              ticketsNow.push({ id: doc.id, ...ticketData });           
            }
          });
          this.setState((prevState) => ({
            pointMonitor: {
              ...prevState.pointMonitor,
              ticketsNow,
            },
          }), () => { });
        })
        :
        setTimeout(() => {
          alert('este sitio no existe'); window.location = '/'
        }, 3500);
    }
  }

  handleUpdateTickets = async () => {
    try {
      const ticketsSnapshot = await getDocs(collection(db, '$:__site-tickets'));
      const ticketsNow = [];
      ticketsSnapshot.forEach((doc) => {
        const ticketData = doc.data();
        if (ticketData.siteName === this.state.siteName) {
          ticketsNow.push({ id: doc.id, ...ticketData });
        }
      });
      this.setState((prevState) => ({
        pointMonitor: {
          ...prevState.pointMonitor,
          ticketsNow,
        },
      }), () => {
      
      });
    } catch (error) {
    
    }
  };


  componentWillUnmount() {
    this.clearUpdateInterval();
  }

  getStatusColor(status) {
    switch (status.toLowerCase()) {
      case 'pendiente a pago':
        return 'yellow';
      case 'cancelado':
        return 'red';
      case 'error':
        return 'red';
      case 'pagado':
        return 'green';
      case 'completado':
        return 'blue';
      case 'preparando':
        return 'orange';
      default:
        return 'black';
    }
  }


  clearUpdateInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  };

  handleModalStatus = (ticket) => {
    this.clearUpdateInterval();
    this.setState({
      modalStatusTicket: true,
      selectedTicket: ticket,
      pointStatusTicket: ticket.status,
    });
  }

  handleCloseModal() {
    this.setState({
      modalStatusTicket: false,
      selectedTicket: null,
      pointStatusTicket: '',
      frameConfirmClose: false,
    }, () => {
      if (!this.state.modalStatusTicket) {
        this.handleUpdateTickets();
      }
    });
  }
  async handleChangeStatus(event) {
    const newStatus = event.target.value;
    const ticketId = this.state.selectedTicket.idTicket;
    const currentDateTime = new Date().toLocaleString();

    const updatedPointMonitor = {
      ...this.state.pointMonitor,
      ticketsNow: this.state.pointMonitor.ticketsNow.map((ticket) =>
        ticket.idTicket === ticketId
          ? { ...ticket, status: newStatus }
          : ticket
      ),
    };

    const updatedLog = [
      ...this.state.pointItemLog,
      {
        ticketId,
        status: newStatus,
        date: currentDateTime,
      },
    ];

    this.setState({
      pointMonitor: updatedPointMonitor,
      pointStatusTicket: newStatus,
      pointItemLog: updatedLog,
    });

    if (newStatus === "cerrar") {
      this.setState({
        secureState: true,
        frameConfirmClose: true,
      });
    }

    try {
      const ticketRef = doc(db, '$:__site-tickets', ticketId);
      await updateDoc(ticketRef, {
        status: newStatus,
        updatedAt: currentDateTime,
      });
    } catch (error) {
    
    }
  }

  handleCloseOffcanvas() {
    this.setState({
      frameConfirmClose: false,
      modalStatusTicket: false,
      secureState: false,
    })
  }

  handleConfirmCloseTicket = async () => {
    const ticketId = this.state.selectedTicket.idTicket;
    const currentDateTime = new Date().toLocaleString();

    const updatedTicketsNow = this.state.pointMonitor.ticketsNow.filter(
      (ticket) => ticket.id !== ticketId
    );

    const closedTicket = this.state.pointMonitor.ticketsNow.find(
      (ticket) => ticket.id === ticketId
    );

    if (!closedTicket) {
     
      return;
    }

    const updatedLog = [
      ...this.state.pointItemLog,
      {
        ticketId,
        status: 'cerrar',
        date: currentDateTime,
      },
    ];

    this.setState({
      pointMonitor: {
        ...this.state.pointMonitor,
        ticketsNow: updatedTicketsNow,
      },
      pointItemLog: updatedLog,
      frameConfirmClose: false,
      modalStatusTicket: false,
      secureState: false,
    });

    try {
      await deleteDoc(doc(db, '$:__site-tickets', ticketId));
      await setDoc(doc(db, '$:__site-close', ticketId), {
        ...closedTicket,
        status: 'cerrar',
        closedAt: currentDateTime,
      });

      this.handleUpdateTickets();
    } catch (error) {
     
    }
  }

  handleSaveStatus = async () => {
    const { pointStatusTicket } = this.state;

    await this.updateTicketInStateAndFirebase({
      status: pointStatusTicket,
    });

    this.setState({
      modalStatusTicket: false,
      pointStatusTicket: '',
    });

    this.handleUpdateTickets();
  };


  cique(c) {
    this.state.txtUserPass === c ? this.setState({ roleCreate: true }) : <></>
  }

  updateTicketInStateAndFirebase = async (updatedFields) => {
    const { selectedTicket, pointMonitor } = this.state
    const currentDateTime = new Date().toLocaleString();

    const newLogEntry = {
      status: updatedFields.status || selectedTicket.status, 
      updatedAt: currentDateTime,
    };
  
    const updatedTicket = {
      ...selectedTicket,
      ...updatedFields,
    }
    const updatedPointMonitor = {
      ...pointMonitor,
      ticketsNow: pointMonitor.ticketsNow.map((ticket) =>
        ticket.idTicket === selectedTicket.idTicket ? updatedTicket : ticket
      ),
    }
    const pointMonitorRef = doc(db, '$:__site-tickets', selectedTicket.idTicket);
    await updateDoc(pointMonitorRef, {
      'pointMonitor.ticketsNow': [updatedTicket],
      logTicket:[...(selectedTicket.logTicket || []), newLogEntry]
    });

   

    this.setState({
      pointMonitor: updatedPointMonitor,
      selectedTicket: updatedTicket,
    })
    this.handleUpdateTickets();
  }




  handleSendProviderMessage = async (e) => {
    e.preventDefault();
    const { newMessage } = this.state;

    if (newMessage.trim() === '') return
    const newProviderMessage = {
      message: newMessage.trim(),
      timestamp: new Date().toLocaleString(),
      sender: "Provider",
    }

    await this.updateTicketInStateAndFirebase({
      chatAndSoportProvider: {
        ...this.state.selectedTicket.chatAndSoportProvider,
        provider: [
          ...(this.state.selectedTicket.chatAndSoportProvider?.provider || []),
          newMessage,
        ],
      },
    })
    this.setState({ newMessage: '' })
  };


  handleSaveStatus = async () => {
    const { pointStatusTicket } = this.state;
    await this.updateTicketInStateAndFirebase({
      status: pointStatusTicket,
    })
    this.setState({
      modalStatusTicket: false,
      pointStatusTicket: '',
    });
    this.handleUpdateTickets();
  }



  render() {

    const { ticketsNow } = this.state;
    if (!this.state.pageExist) {
      return <div>Cargando...</div>;
    }

    return (
      <>
        {this.state.roleCreate === false ?
          <div className='App-header'>
            <Card>
              <Cique cique={(e) => { this.cique(e) }} />

            </Card></div> : <></>}
        {this.state.roleCreate === true ?
          <div className='App-header'>
            <div className='container'>
              <Row>
                {this.state.pointMonitor?.ticketsNow && this.state.pointMonitor.ticketsNow.length > 0 ? (
                  this.state.pointMonitor.ticketsNow.map((outerTicket, outerIndex) => (
                    outerTicket.pointMonitor?.ticketsNow && outerTicket.pointMonitor.ticketsNow.length > 0 ? (
                      outerTicket.pointMonitor.ticketsNow.map((innerTicket, innerIndex) => (
                        <Col key={`${outerIndex}-${innerIndex}`} md={4}>
                          <Card className="mb-3" onClick={async ()=> {await this.handleUpdateTickets(); this.handleModalStatus(innerTicket)}}>
                            <Card.Body>
                              <Card.Title>{innerTicket.nameId}</Card.Title>
                              <CardGroup className="ticket-card">
                                {innerTicket.data && typeof innerTicket.data === 'object' ? (
                                  Object.entries(innerTicket.data).map(([key, value]) => (
                                    Array.isArray(value) ? (
                                      <table key={key} style={{ fontSize: '0.8em', width: '100%', marginBottom: '1em' }}>
                                        <thead>
                                          <tr>
                                            <th>Para</th>
                                            <th>Cantidad</th>
                                            <th>Precio</th>
                                            <th>Detalles</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {value.map((item, valueIndex) => (
                                            item.keyItems.map((subItem, subIndex) => (
                                              <tr key={`${key}-${valueIndex}-${subIndex}`}>
                                                <td>{item.name || 'N/A'}</td>
                                                <td>{subItem.clicks || 'N/A'}</td>
                                                <td>{subItem.priceItem || 'N/A'}</td>
                                                <td>{subItem.keyItem || 'N/A'}</td>
                                              </tr>
                                            ))
                                          ))}
                                        </tbody>
                                      </table>
                                    ) : (
                                      <Card.Text key={key} style={{ fontSize: '0.8em' }}>
                                        <strong>{key}:</strong> {value.name || 'N/A'}<br />
                                        <strong>Cantidad:</strong> {value.clicks || 'N/A'}
                                      </Card.Text>
                                    )
                                  ))
                                ) : (
                                  <Card.Text>No hay datos disponibles.</Card.Text>
                                )}
                              </CardGroup>
                              <Card.Text>
                                <strong>Total Cost:</strong> {innerTicket.totalCost}
                              </Card.Text>
                              <Card.Text>
                                <strong>Status:</strong>
                                <span style={{ color: this.getStatusColor(innerTicket.status), fontSize: '0.48em' }}>
                                  {innerTicket.status}
                                </span>
                              </Card.Text>
                              <Card.Footer>
                                <small className="text-muted" style={{ fontSize: '0.48em' }}>ID: {innerTicket.idTicket}</small>
                              </Card.Footer>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))
                    ) : null
                  ))
                ) : (
                  <div>No hay tickets disponibles</div>
                )}
              </Row>
              <Offcanvas className='z-modal-con' show={this.state.frameConfirmClose} onHide={this.handleCloseOffcanvas} placement="bottom">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Confirmar Cierre de Ticket</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <p>¿Estás seguro de que deseas cerrar este ticket?</p>
                  <Button variant="danger" onClick={this.handleConfirmCloseTicket}>
                    Cerrar Ticket
                  </Button>
                  <Button variant="secondary" onClick={this.handleCloseOffcanvas}>
                    Cancelar
                  </Button>
                </Offcanvas.Body>
              </Offcanvas>

              <Modal className='z-modal-con' show={this.state.modalStatusTicket} onHide={this.handleCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Cambiar Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Card className='z-modal-con'>
                    <CardBody>
                      <Form.Group controlId="formStatusSelect">
                        <Form.Label>Seleccione el nuevo status</Form.Label>
                        <Form.Control
                          as="select"
                          value={this.state.pointStatusTicket}
                          onChange={this.handleChangeStatus}
                        >
                          <option value=""></option>
                          <option value="preparando">Preparando</option>
                          <option value="pagado">Pagado</option>
                          <option value="cancelado">Cancelado</option>
                          <option value="cerrar">Cerrar</option>
                        </Form.Control>
                      </Form.Group>
                      <div className="chat-section mt-4">
                        <div className="chat-section mt-4">
                          <h5>Chat con el proveedor</h5>
                          <div
                            className="chat-box"
                            style={{
                              maxHeight: '200px',
                              overflowY: 'scroll',
                              border: '1px solid #ccc',
                              padding: '10px',
                            }}
                          >
                            {this.state.selectedTicket ? (
                              <div style={{ marginBottom: '20px' }}>
                                {this.state.selectedTicket.chatAndSoportProvider?.provider?.length > 0 ? (
                                  this.state.selectedTicket.chatAndSoportProvider.provider.map((msg, index) => (
                                    <div key={`provider-${index}`} style={{ marginBottom: '10px' }}>
                                      <strong>Proveedor:</strong> {msg}
                                    </div>
                                  ))
                                ) : (
                                  <div style={{ marginBottom: '10px' }}>
                                    <em>No hay mensajes del proveedor.</em>
                                  </div>
                                )}
                                {this.state.selectedTicket.chatAndSoportUser?.user?.length > 0 ? (
                                  this.state.selectedTicket.chatAndSoportUser.user.map((msg, index) => (
                                    <div
                                      key={`user-${index}`}
                                      style={{ marginBottom: '10px', textAlign: 'right' }}
                                    >
                                      <strong>Cliente:</strong> {msg}
                                    </div>
                                  ))
                                ) : (
                                  <div style={{ marginBottom: '10px', textAlign: 'right' }}>
                                    <em>No hay mensajes del cliente.</em>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div>No hay ningún ticket seleccionado.</div>
                            )}
                          </div>
                        </div>
                        <Form className="mt-3" onSubmit={(e) => this.handleSendProviderMessage(e)}>
                          <Form.Group>
                            <Form.Control
                              type="text"
                              placeholder="Escribe tu mensaje..."
                              value={this.state.newMessage}
                              onChange={(e) => this.setState({ newMessage: e.target.value })}
                            />
                          </Form.Group>
                          <button className="btn btn-primary mt-2" type="submit">Enviar</button>
                        </Form>
                      </div>
                    </CardBody>
                  </Card>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseModal}>
                    Cerrar
                  </Button>
                  <Button variant="primary" disabled={this.state.secureState} onClick={this.handleSaveStatus}>
                    Guardar Cambios
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div> : null
        }
      </>
    );
  }

}
